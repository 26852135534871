<script>
export default {
  name: "RequestFormRateCost.vue",

  props: {
    rate: { type: Object,require: true, default: () => {}},
    showLoader: { showLoader: Boolean, default: false},
    addServices: { type: Array }
  },

  data() {
    return {
      addservicetotal: 0,
    };
  },

  watch: {
    addServices: {
      handler() {
        this.getaddservicetotal();
      },
      deep: true,
    },
  },

  computed: {
    windowSize() {
      return document.documentElement.clientWidth;
    },

    filteredServices() {
      return this.addServices.filter(service => Number(service.cost) !== 0);
    }
  },

  mounted() {
    this.getaddservicetotal();
  },

  methods: {
    getnumber(value) {
      return parseFloat(value).toLocaleString("ru-RU");
    },

    getaddservicetotal() {
      this.addservicetotal = 0
      for (let i = 0; i < this.addServices.length; i++) {
        this.addservicetotal =
          this.addservicetotal + this.addServices[i].cost;
      }
    },

    getImage(service){
      switch(service.icon[0]) {
        case "1":
          return require("../../assets/img/icons/ship.svg")
        case "2":
          return require("../../assets/img/icons/train.svg")
        case "3":
          return require("../../assets/img/icons/container.svg")
        case "4":
          return require("../../assets/img/icons/car.svg")
      }
    },
  },
};
</script>

<template>
  <div class="services-cost-side">
    <div class="services-blocks">
      <p class="services-cost-title">Основные услуги</p>
      <div class="services-cost services-cost--main"> 
        <div class="services-cost__header">
          <p class="services-cost__title">Этап</p>
          <p class="services-cost__title">Курс</p>
          <p class="services-cost__title">Цена</p>
        </div>
        <div
          class="services-cost__row-items"
          v-for="service in rate.rate.detail"
          :key="service.id"
        >
          <div class="services-cost__row-item services-cost__row-item--step">
            <img 
              class="services-cost__item-icon"
              :src="getImage(service)" 
              :alt="service.service"
            >
            <p>{{ service.service }}</p>
          </div>
          <div class="services-cost__row-item services-cost__row-item--course">
            {{ service.cbr_rate }}
          </div>
          <div class="services-cost__row-item services-cost__row-item--price">
            <div class="services-cost__column-prices">
              <div>
                {{ getnumber(service.summa) }}&nbsp;
                <span v-if="service.cur_code == 'USD'">&#36;</span>
                <span v-else>&#8381;</span>
              </div>
              <div>
              {{ getnumber(service.rmb_price) }}&nbsp;
              <span v-if="service.cur_code == 'USD'">&#165;</span>
              <span v-else>&#165;</span>
              </div>
            </div>
            <span class="rub-price">{{ getnumber(service.cost) }} &#8381;</span>
          </div>
        </div>
        <div class="services-cost__bottom">
          <p class="services-cost__bottom-title">Итого:</p>
          <span class="services-cost__bottom-price">{{ getnumber(rate.rate.total) }}&nbsp;&#8381;</span>
        </div>
      </div>
      <p class="services-cost-title" v-if="addServices.length > 0  && addservicetotal > 0">Дополнительные услуги</p>
      <div class="services-cost services-cost--optional" v-if="addServices.length > 0 && addservicetotal > 0">
        <div v-if="windowSize > 1200" class="services-cost__header">
          <p class="services-cost__title">Этап</p>
          <p class="services-cost__title">Цена</p>
        </div>
        <div class="services-cost__column-items">
          <div class="services-cost__row-items services-cost__row-items--service" v-for="(service, index) in filteredServices" :key="index">
            <div class="services-cost__row-item services-cost__row-item--name">
              {{ service.service }}
            </div>
            <div class="services-cost__row-item services-cost__row-item--price">
              {{ service.cost }} &#8381;
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services-total-cost">
      <p class="services-total-cost__title">Полная стоимость заявки</p>
      <span class="services-total-cost__price">{{ getnumber(addservicetotal + rate.rate.total)}}&nbsp;&#8381;</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.services-cost-title{
  color: var(--grey-100);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-bottom: 8px;

  @media (max-width: $mobile-width) {
    font-size: 12px;
  }
}

.services-cost-side {
  position: relative;
  height: auto;
  display: flex;
  //justify-content: space-between;
  flex-direction: column;
}

.services-cost {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px 18px 18px 16px; 

  @media (max-width: $mobile-width){
    padding: 20px 16px 12px; 
  }

  &--main{
    margin-bottom: 24px;
  }

  &__header,
  &__row-items {
    display: grid;    
    grid-template-columns: 95px 50px minmax(159px, max-content);
    justify-content: space-between;

    @media (max-width: $tablet-width){
      grid-template-columns: 1fr .6fr 1fr;
    }

    @media (max-width: $mobile-width){
      grid-template-columns: .8fr .6fr max-content;
      gap: 0 14px;
    }
  }

  &__header {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--grey-20);
  }

  &__row-items {
    padding: 12px 0;

    &:not(:last-child){
      border-bottom: 1px solid var(--grey-20);
    }

  }

  &--optional{
    @media (max-width: $tablet-width){
      padding: 9px 24px;
    }

    .services-cost__header{
      display: flex;
      justify-content: space-between;
    }

    .services-cost__column-items{
      display: flex;
      flex-direction: column;
      margin-top: 14px;
      gap: 14px 0;

      @media (max-width: $tablet-width){
        max-height: 150px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px 27px;
        margin-top: unset;
      }

      @media (max-width: $mobile-width){
        max-height: unset;
        flex-wrap: nowrap;
        flex-direction: column;
      }
    }

    .services-cost__row-items{
      display: flex;
      justify-content: space-between;
      border-bottom: unset;
      padding: unset;

      @media (max-width: $tablet-width){
        max-width: 300px;
        flex: 1 1 33% ;
        align-items: center;
      }

      @media (max-width: 944px){

      }

      @media (max-width: $mobile-width){
        flex: unset;
        max-width: unset;
      }
    }

    .services-cost__row-item--name{
      font-weight: 400;
    }

  }

  &__item-icon{
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &__row-item {
    display: flex;
    align-items: center;
    color: var(--grey-70);
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;

    @media (max-width: $mobile-width){
      font-size: 12px;
    }

    &--step{
        font-weight: 400;
    }

    &--price {
      display: flex;
      align-items: center;
      gap: 0 24px;
      justify-content: space-between;
      
      & .rub-price{
        font-weight: 700;
        color: var(--grey-100);
        min-width: 76px;

        @media (max-width: $mobile-width){
          min-width: 60px;
        }
      }

      @media (max-width: $tablet-width){
        justify-content: flex-end;
        gap: 0 68px;
      }

      @media (max-width: $mobile-width){
        gap: 0 13px;
      }
    }
  }

  &__column-prices {
    display: flex;
    flex-direction: column;

    @media (max-width: $tablet-width){
      flex-direction: row;
      gap: 0 38px;
    }

    @media (max-width: $mobile-width){
      flex-direction: column;
      gap: unset;
    }
  }

  &__title{
    color: var(--grey-70);
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.12px;
  }

  &__bottom{
    width: fit-content;
    margin-left: auto;
    display: flex;
    gap: 0 18px;
    align-items: center;
    margin-top: 16px;

    @media (max-width: $tablet-width){
      margin-top: 8px;
    }

    &-title{
      color: var(--grey-100);
      font-family: 'Manrope', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.14px;
    }

    &-price{
      color: var(--grey-100);
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.16px;
    }
    
  }
}

.services-total-cost{
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  line-height: normal;
  max-width: fit-content;
  margin-left: auto;
  margin-top: 18px;
  display: flex;
  align-items: flex-end;
  gap: 0 17px;

  @media (max-width: $tablet-width){

  }

  &__title{
    color: var(--grey-70);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
  }

  &__price{
    color: var(--blue-70);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.24px;
    line-height: 28px;
  }
}
</style>

<script>
import AppInput from "@/components/ui/AppInput.vue";
import AppDropDown from "@/components/ui/AppDropDown.vue";
import AppDropFile from "@/components/ui/AppDropFile.vue";
import AppAlertPlate from "@/components/ui/AppAlertPlate.vue";

import { order_request_form_new } from "../jsons/order-request-form-new";

export default {
  name: 'ConsigneeInfoForm',
  components: {
    AppDropDown,
    AppInput,
    AppDropFile,
    AppAlertPlate
  },

  props: {
    rate: { type: Object, required: true },
    cons_name: { type: String, default: null, required: false },
    storeData: { Object, default: null },
    order_key: Number
  },

  data: () => ({
    elements: order_request_form_new.consignee_info,
    valid: true,
    showLoader: false,

    profileData: [],
    innList: [],
    is_low_rate: false,
    payersData: [],
    selected_payers: [],
    disabledInputs: false,
    files: [],

    formdata: {
      consignee_address: '',
      consignee_name: '',
      consignee_inn: '',
    }
  }),

  computed: {
    filteredObj() {
      return Object.fromEntries(
        Object.entries(this.elements).filter(([key]) => key !== 'consignee_name')
      );
    },
  },

  watch: {
    storeData: {
      handler() {
        this.fillFromStore()
      },
      deep: true,
    },
  },

  mounted() {
    this.getUser()
    if (this.rate.is_nak === 1 && this.$_getsetting('client_id') !== -1) {
      this.getNakInn();
    }
  },

  methods: {
    getNakInn() {
      this.$postapi(this.$address + this.$getters.getNakInnList.uri, {
        method: this.$getters.getNakInnList.name,
        key: this.rate.key_orig,
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0){
          this.innList = JSON.parse(data.message).inns
        }
      });
    },

    fillFromStore() {
      if (this.storeData) {
        this.formdata.consignee_name = this.storeData.consignee_name
        this.formdata.consignee_address = this.storeData.consignee_address
        this.formdata.consignee_inn = this.storeData.consignee_inn
        this.selected_payers = this.storeData.consignee_name || [];
      }
    },

    validate() {
      const inputs = this.$refs.recipientFormInputs;
      this.valid = true;

      inputs.forEach(input => {
        const isValid = input.validate();
        if (!isValid) {
          this.valid = false;
        }
      });

      if (this.formdata.consignee_name.length === 0) { this.valid = false; }

      return this.valid;
    },

    sortPayersData() {
      this.payersData = this.profileData.payers_data.filter(obj => this.innList.includes(obj.inn));
    },

    getUser() {
      this.showLoader = true
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0){
          this.profileData = JSON.parse(data.message)
          this.payersData = this.profileData.payers_data
          if (this.rate.is_nak == 1) {
            this.sortPayersData()
          }
          this.showLoader = false
        }
      });
    },

    openLowRatingForm() {
      let index = this.profileData.payers_data.findIndex(el => el.inn === this.formdata.consignee_inn);
      var queryParams = {
        cid: this.profileData.payers_data[index].id
      }
      let routeData = this.$router.resolve({
        path: "/LowRatingForm",
        query: queryParams
      });
      window.open(routeData.href, '_blank');
    },

    fillUserById(data) {
      this.is_low_rate = false
      if (typeof(data.value) === 'string') {
        this.formdata.consignee_name = data.value
      } else {
        this.formdata.consignee_name = data.name
        this.formdata.consignee_inn = data.inn
        if (data.company_rate <= 6 && this.profileData.is_show_notice === 1) {
          this.is_low_rate = true
        }
        this.getUserByInn(data.inn)
      }

      this.disabledInputs = true
    },

    getUserByInn(inn) {
      this.$getapi(this.$address + this.$getters.getCompanyByInn.uri + inn)
        .then(data => {
          if (data.companyname){
            this.companyInfo = data
            this.formdata.consignee_address = this.companyInfo.address
            this.$emit('updateField', { consignee_address: this.formdata.consignee_address });
          }
        });
    },

    OnEditChange() {
      return this.formdata;
    },

    fileUploaded(value) {
      this.files = value
    },

    fileUpdate() {
      this.$emit('updateFile', this.files)
    },
  }
}
</script>
<template>
  <v-form
    ref="consigneeInfoForm"
    v-model="valid"
  >
    <div class="cv-grid-div mt-4 mb-3">
      <AppDropDown
        class="request-form__input"
        v-if="profileData.hasOwnProperty('payers_data') && payersData.length > 0"
        :label="elements.consignee_name.caption"
        :options="payersData"
        item-text="shortname"
        item-value="id"
        required
        editable
        :loading="showLoader"
        return-object
        v-model="selected_payers"
        placeholder="Выберите получателя"
        autocomplete
        :rules="elements.consignee_name.rules"
        @input="fillUserById"
        @changeInput="formdata.consignee_name = $event, $emit('validateForm')"
      />

      <AppInput
        v-else
        class="request-form__input"
        v-model="formdata.consignee_name"
        :label="elements.consignee_name.caption"
        :loading="showLoader"
        :rules="elements.consignee_name.rules"
        required
        @input="is_low_rate = true, $emit('validateForm')"
      />

      <AppInput
        v-for="element in filteredObj"
        :key="element.name"
        ref="recipientFormInputs"
        v-model="formdata[element.name]"
        :label="element.caption"
        :rules="element.rules"
        class="request-form__input"
        :editable="disabledInputs"
        @input="$emit('validateForm')"
        required
      />
    </div>

    <AppAlertPlate v-if="is_low_rate" color="var(--orange-70)" background-color="var(--orange-10)" class="request-form__alert">
      <div slot="title">Необходимы дополнительные документы для  подтверждения надежности юр. лица</div>
      <span slot="description">
        На данный момент согласована работа только по предоплате. Чтобы получить отсрочку до выгрузки, необходимо
        <span class="request-form__alert-link" v-if="profileData.hasOwnProperty('payers_data')" @click="openLowRatingForm">заполнить форму </span>
        <router-link v-else to="/AddCompanyData" class="request-form__alert-link">заполнить форму </router-link>
      </span>
    </AppAlertPlate>

    <AppDropFile
      :temp_id="order_key"
      label="Загрузите документ"
      @fileUploaded="fileUploaded"
    />
  </v-form>
</template>
<style scoped lang="scss">
.request-form {

  &__alert {
    margin-bottom: 24px;

    &-link {
      cursor: pointer;
      font-weight: 500;
      color: var(--blue-50);
      display: contents;
      text-decoration: underline !important;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &__input {
    width: 100%;
    position: relative;
  }
}

.cv-grid-div {
  display: grid;
  z-index: 400;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  justify-items: flex-start;

  :last-child {
    grid-column: 1 / -1;
  }

  &:last-child {
    padding-bottom: 235px;
  }

  @media (max-width: $mobile-width) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
